@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

a {
  color: #000;
}

.template6 {
  font-family: 'Poppins', sans-serif;
  letter-spacing: 1px;
}

.template6 .preloader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #ffd750;
  z-index: 999999;
}

.template6 .preloader .spinner {
  width: 70px;
  height: 70px;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: -30px auto 0;
}

.template6 .preloader .double-bounce1,
.template6 .preloader .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #fff;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  animation: sk-bounce 2s infinite ease-in-out;
}

.template6 .preloader .double-bounce2 {
  animation-delay: -1s;
}

@keyframes sk-bounce {

  0%,
  100% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }
}


@keyframes sk-bounce {

  0%,
  100% {
    transform: scale(0.0);
  }

  50% {
    transform: scale(1.0);
  }
}

.template6 .logo {
  width: 240px;
}

.template6 h2 {
  font-size: 36px;
}

.template6 .line {
  height: 1px;
  background-color: #800e13;
}

.template6 .navbar-section-6 nav {
  position: absolute;
  padding: 13px 0px;
  z-index: 1;
  right: 0;
  left: 0;
  background-color: #ffd750;
}

.template6 .navbar-section-6 nav i {
  padding-right: 10px;
}

.template6 .navbar-section-6 nav p {
  color: #000;
}

.template6 .navbar-section-6 nav a {
  color: #000 !important;
}

.template6 .banner-section-6 {
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.template6 .banner-section-6::before {
  background: #0a0a0a;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  opacity: 0.5;
  z-index: -1;
}

.template6 .banner-section-6 .video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -2;
}

@media (max-width: 767px) {
  .template6 .banner-section-6 .video-bg {
    height: 100%;
    width: auto;
    min-width: 100%;
  }
}

.template6 .about-section-6 .icon {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  width: 48px;
  height: 48px;
  border-radius: 50% !important;
  background-color: #ffd750;
}

.template6 .about-section-6 h1 {
  font-weight: bold;
  color: #1c2035;
}

.template6 .about-section-6 h5 {
  font-weight: bold;
  color: #1c2035;
}

.template6 .about-section-6 .counter {
  padding: 100px 0px;
}

.template6 .services-section-6 {
  padding: 100px 0px;
}

.template6 .services-section-6 h4 {
  font-weight: bold;
  text-transform: uppercase;
}

.template6 .services-section-6 p {
  color: #9b9b9b;
}

.template6 .Project-tempalte-6 {
  padding: 100px 0px;
}

.template6 .Project-tempalte-6 h4 {
  color: #fff;
  position: relative;
}

.template6 .Project-tempalte-6 p {
  color: #9b9b9b;
  position: relative;
}

.template6 .Project-tempalte-6 .card-img-overlay::before {
  background: #111;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  opacity: 0.5;
}

.template6 .contact-section-6 {
  padding: 100px 0px;
}

.template6 .contact-section-6 svg {
  color: #ffd750;
}

.template6 .contact-section-6 button {
  background-color: #ffd750;
  color: #000;
}

.template6 .footer-tempalte-6 {
  background-color: #ffd750;
  padding: 100px 0px;
  position: relative;
}

.template6 .footer-tempalte-6 p {
  z-index: 1;
  color: #000;
}

.template6 .footer-tempalte-6 ul {
  z-index: 1;
}

.template6 .footer-tempalte-6::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  opacity: 0.3;
}

.template6 .footer-tempalte-6 .neokode {
  color: #555;
}

@media (max-width: 990px) {
  .template6 .navbar-section-6 nav {
    padding: 20px 20px;
  }
}